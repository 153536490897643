$primary: #e50b4c;
$secondray: #fff;
$BLK: #000;

img {
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;

  @media screen and (max-width: 500px) {
    padding-left: 16px;
  }
}

.app-container a {
  color: $primary;
  font-weight: 700;
  border-right: 1px solid $primary;
  padding: 5px 15px;

  &:last-child {
    border-right: none;
  }
}

.nav-items {
  display: flex;
  justify-content: center;
  background: $secondray;
}

div.MuiContainer-maxWidthMd {
  max-width: 750px;
}

a.textSecondary {
  color: $BLK;
  text-decoration: none;
  padding-right: 20px;

  &:hover {
    color: $primary;
    font-weight: 700;
    text-decoration: none;
  }
}

p.copyright {
  color: $secondray;
}

div.banner-title {
  color: $primary;
  text-align: center;

  >h1 {
    font-size: 80px;
    margin-top: 15px;
    margin-bottom: 15px;

    @media screen and (max-width: 1600px) {
      font-size: 60px;
    }

    @media screen and (max-width: 1120px) {
      font-size: 30px;
    }

    @media screen and (max-width: 468px) {
      text-align: center;
    }
  }

  >span {
    font-size: 36px;
    color: $BLK;

    @media screen and (max-width: 1600px) {
      font-size: 42px;
    }

    @media screen and (max-width: 1120px) {
      font-size: 22px;
    }

    @media screen and (max-width: 468px) {
      text-align: center;
      display: block;
    }
  }
}

@media screen and (max-width: 991px) {
  header.MuiAppBar-colorPrimary {
    left: auto;
  }
}

.banner-warpper {
  position: relative;

  >img {
    @media screen and (max-width: 468px) {
      margin-top: 50px;
    }
  }
}

.banner-title {
  position: absolute;
  top: 100px;
  left: 50%;

  @media screen and (max-width: 1600px) {
    top: 50px;
  }

  @media screen and (max-width: 768px) {
    top: 50px;
  }

  @media screen and (max-width: 468px) {
    position: relative;
    left: auto;
    padding-left: 15px;
    top: auto;
  }
}

.address span {
  display: block;

  &:first-child {
    font-weight: 700;
  }
}

div.address {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

input.MuiOutlinedInput-input {
  padding: 10px 14px;
}

div.adr {
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
}

#blog svg.MuiSvgIcon-root {
  font-size: 6rem;
  color: $primary;
}

button.MuiButton-root,
div.MuiOutlinedInput-root {
  border-radius: 0;
  height: 40px;
}

button.slick-prev,
button.slick-next {
  width: auto;
  height: auto;

  &::before {
    font-size: 4rem;
    color: $BLK;
    width: 40px;
    height: 40px;
  }
}

button.slick-next {
  right: 0;
  z-index: 999;

  @media (min-width: 478px) and (max-width: 991px) {
    right: 0px;
  }
}

button.slick-prev {
  left: 0px;
  z-index: 999;
}

div.slick-track>div {
  position: relative;
}

.slick-slide img {
  max-width: 2400px;
  margin: 0 auto;
}

.css-1thdg65-ErrorContainer {
  display: none;
}

.menu-item {
  color: #000;
  text-decoration: none;

  &.active {
    color: $primary;
    font-weight: 600;
  }

  &.flow {
    flex-grow: 1;
  }
}

a.notfound-link {
  color: $primary;
  display: block;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}

.form {
  margin-top: 30px;

  &.desktop {
    @media screen and (max-width:767px) {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media screen and (max-width:767px) {
      margin-top: 30px;
      display: block;
    }
  }

  div:not(.MuiAutocomplete-endAdornment) {
    background-color: #fff;
    height: 55px;
    border: none;

    @media screen and (max-width: 500px) {
      display: block;
      max-width: 90%;
      margin-bottom: 15px;
      text-align: center;
      margin-left: 15px;
    }
  }

  >button.MuiButtonBase-root {
    height: 55px;
    background-color: #e50b4c;
    color: #fff;

    &:hover,
    &:active {
      color: #e50b4c;
      background-color: #fff;
      border: 1px solid #e50b4c;
    }
  }

  @media screen and (max-width: 1350px) {
    position: relative;
    top: auto;
    left: auto;
    text-align: center;
    margin-top: 15px;
  }
}

.autocomplete-input {
  width: 300px;

  @media screen and (max-width: 500px) {
    width: auto;
  }
}

img.logo {
  width: 150px;
  margin-right: 45px;

  &-mobile {
    width: 150px;
    padding: 10px 15px;
  }
}

div.header-desktop {
  @media screen and (max-width: 1130px) {
    display: none;
  }
}

.header-mobile {
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 1131px) {
    display: none;
  }
}

.giver-section {
  margin-top: 120px;
  margin-bottom: 30px;

  ul {
    padding-left: 16px;
  }
}

div.loader-wrapper {
  position: relative;
  top: -50px;

  >div.loader {
    color: $primary;
    position: absolute;
    left: 50%;
  }
}

p.text {
  color: #333;
}

div.name {
  display: flex;
}

div#menuButton {
  margin-right: 16px;
  color: #fff;
  position: relative;
  text-align: center;
  outline: none;

  img {
    width: 90%;
    max-width: 100%;
    border-radius: 10px;
  }

  h3 {
    position: absolute;
    bottom: 15%;
    padding: 0 30px;
    left: 0;
    right: 0;
  }

  svg {
    position: relative;
    bottom: 30px;
    visibility: hidden;
    left: 0;
    color: $primary;
    right: 0;
  }

  &:hover {
    transform: scale(1.05);
    outline: none;

    img {
      border: 2px solid $primary;
      border-top: 9px solid $primary;
    }

    h3 {
      color: $primary;
    }

    svg {
      visibility: visible;
    }
  }

  &.not_active {
    outline: none;

    img {
      position: relative;
      border: 0;
    }

    &:hover {
      transform: none;

      h3 {
        color: $secondray;
      }

      svg {
        visibility: hidden;
      }
    }
  }

  &.is_active {
    outline: none;

    img {
      position: relative;
      border: 0;
    }

    &::after {
      content: "";
      position: absolute;
      left: 42%;
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 30px solid #fff;
      clear: both;
    }

    &:hover {
      transform: none;

      h3 {
        color: $secondray;
      }

      svg {
        visibility: hidden;
      }
    }
  }
}

.car-title {
  text-align: center;
}

.login-buttons {
  display: flex;

  a {
    color: #e50b4c;

    &:hover {
      text-decoration: none;
    }
  }
}

#menu-appbar {
  a {
    color: #e50b4c;

    &:hover {
      text-decoration: none;
    }
  }
}

p.no-courses {
  color: #e50b4c;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

div.slider-container {
  margin-left: 22px;
  margin-right: 22px;
  margin-top: 44px;
}

a.menu-item {
  margin-right: 15px;

  &.with-border {
    border-right: 3px solid $primary;
    padding-right: 15px;
  }

  &:hover {
    text-decoration: none;
    color: $primary;
    font-weight: 700;
  }

  &.menu-item-button {
    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }
}

.form {
  @media screen and (max-width: 680px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    div:not(.MuiAutocomplete-endAdornment) {
      width: 300px;
      margin-bottom: 15px;
      max-width: none;
      margin-left: 0;
    }
  }
}

div.filter-result {
  background-color: #0e0502;
  color: $secondray;
  padding: 15px;
  margin-top: -28px;

  padding-top: 40px;

  span {
    display: block;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1100px) {
    margin-left: 18px;
    margin-right: 18px;
  }

  @media screen and (max-width: 767px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

div.course_desc {
  display: none;

  &:nth-of-type(1) {
    display: block;
  }
}

div.filter-cards {
  div.slick-track {
    margin-left: 0;
    margin-right: 0;
  }
}

svg.close_button {
  float: right;
  position: relative;
  top: -30px;
}

.just-filterd {
  .course {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}

div.mui-focused input {
  border-color: $primary;
}

.last_slider {
  div#menuButton_last {
    margin-right: 16px;
    color: #fff;
    position: relative;
    text-align: center;
    outline: none;
    transition: all 0.05s ease-in-out;

    img {
      width: 90%;
      max-width: 100%;
    }

    h3 {
      position: absolute;
      bottom: 15%;
      left: 0;
      right: 0;
    }

    &:hover {
      h3 {
        color: $primary;
      }
    }
  }
}

.adr p {
  margin-top: 0;
  line-height: 1.5;
  margin-bottom: 0;
  color: #000;
  text-decoration: none;
}

.adr a {
  margin-top: 0;
  line-height: 1.5;
  margin-bottom: 0;
  color: #000;
  text-decoration: none;

  &:hover {
    font-weight: 700;
    text-decoration: underline;
  }
}

.primary-button {
  background-color: #e50b4c;
  color: #fff;
  height: 40px;
  margin-left: 15px;
  padding: 10px 15px;
  text-transform: uppercase;

  &:hover,
  &:active {
    color: #e50b4c;
    background-color: #fff;
    border: 1px solid #e50b4c;
  }
}

.inline p {
  margin-top: 0;
  margin-bottom: 5px;
}

.datenschutz {
  margin-top: 100px;

  h1 {
    font-size: 2rem;
    text-transform: uppercase;
    color: #e50b4c;
  }

  h2 {
    font-size: 1.5rem;
    color: #888;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.2rem;
    color: #5c666b;
  }

  a {
    font-weight: 700;
    color: #e50b4c;
    word-break: break-word;
  }

  ul {
    padding-left: 2rem;
  }
}

#label {
  color: #e50b4c;
  padding-bottom: 10px;
}

span.error {
  display: block;
  padding-top: 10px;
  color: #e50b4c;
  font-weight: 700;
}

textarea {
  width: calc(100% - 40px);
  padding: 20px;

}


.second-row {
  clear: both;
}

.form-layout {
  margin-top: 50px;
}

label.MuiFormControlLabel-root {
  margin-right: 5px;
}

label.MuiFormControlLabel-root+span.star {
  color: #e50b4c;
}

.social-media {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

#formular::before {
  content: '';
  display: block;
  height: 75px;
  margin-top: -75px;
  visibility: hidden;
}

div.more-courses {
  display: flex;

  justify-content: center;
  margin-top: 30px;

  a {
    background-color: $primary;
    color: $secondray;
    padding: 16px 12px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      background-color: $secondray;
      color: $primary;
      text-decoration: none;
      border: 1px solid $primary;
    }
  }
}