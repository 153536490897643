body {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: "MerriweatherBold";
  src: local("MerriweatherBold"),
    url(./fonts/Merriweather-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "OpenSansRegular";
  src: local("OpenSansRegular"),
    url(./fonts/OpenSans-Regular.ttf) format("truetype");
}

html,
body,
button,
div,
input,
textarea,
div.MuiInputBase-root,
button.MuiButton-root,
p.MuiTypography-body1 {
  font-family: "OpenSansRegular", sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5.MuiTypography-h5 {
  font-family: "MerriweatherBold", serif;
}

h6.MuiTypography-h6,
a.textSecondary {
  font-family: "OpenSansRegular", sans-serif;
}

h1 {
  font-family: "MerriweatherBold", serif;
  font-size: 96px;
}

h5 {
  font-family: "OpenSansRegular", serif;
  font-size: 1rem;
  color: #e50b4c;

  margin-bottom: 0;
}

h2 {
  font-family: "MerriweatherBold", serif;
  font-size: 42px;
  color: #e50b4c;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
}
a {
  color: #000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

