.postlist {
    width: 100%;
    max-width: 100%;
    margin: auto;
    text-align: center;
    background-color: hsla(0,0%,86.7%,.87);
    padding: 15px 0;
}

.post {
    margin: auto;
}

.page-content {
    width: 100%;
    margin: auto;
    text-align: center;
}

.notfound {
    text-align: center;
}

.notfound {
    text-align: center;
}

.slick-slide img{
    max-width: 100%;
}

.layout{
margin-top: 80px;
}